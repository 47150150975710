.typo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.typo.copy {
  z-index: 2;
}

.typo > span.title {
  position: absolute;
  top: 10px;
  left: 5px;
  writing-mode: tb-rl;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.typo.copy > span.title {
  width: 15px;
  bottom: 10px;
  right: 5px;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}
