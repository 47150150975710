.typo-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: 1px solid white;
}

.typo-header > h1 {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 50px;
  font-size: 2.75rem;
}

.typo-header > h1 > span {
  margin-left: 30px;
  font-size: 1rem;
  font-weight: 300;
}

.typo-header .options {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
}

.typo-header button {
  background-color: transparent;
  height: 100%;
  border: 0;
  color: white;
  padding: 0 30px;
  font-size: 1rem;
  cursor: pointer;
}

.typo-header button:hover {
  background-color: black;
}

.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 9;
}

.dialog > span {
  font-size: 2rem;
}

.dialog > div {
  width: 50vw;
  display: flex;
  flex-direction: column;
  background-color: rgb(0, 0, 0);
  padding: 10px 30px;
  border: 1px solid rgb(255, 255, 255);
}

.dialog div label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.dialog div label span.content {
  align-self: flex-start;
}

.dialog div label span {
  flex: 1;
}

.dialog div label input {
  flex: 2;
}

.dialog div label textarea {
  flex: 2;
  height: 120px;
}

.dialog div label.update {
  justify-content: center;
  margin-top: 10px;
}

.dialog div label.update button {
  padding: 10px 90px;
  background-color: #444;
}

.vdiff {
  min-height: 24px;
  visibility: hidden;
  position: relative;
}

.vdiff:after {
  width: 100%;
  display: inline-block;
  visibility: visible;
  content: "Visual diff: Off";
  color: white;
  text-align: center;
  padding: 5px 0;
  background-color: #222;
}

.vdiff:checked:after {
  content: "Visual diff: On";
  background-color: #888;
}
