@font-face {
  font-family: "julietta-messie-ooer";
  src: url("./fonts/julietta-messie-ooer.woff2") format("woff2"),
    url("./fonts/julietta-messie-ooer.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "movementv";
  src: url("./fonts/movementv.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.App {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: #282c34;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 70px 45vh auto;
  grid-template-areas: "header header" "typo typo" "left right";
  grid-gap: 2px;
  z-index: 7;
}

.typo-header {
  grid-area: header;
}

.typo-splitscreen {
  position: relative;
  grid-area: typo;
  overflow: hidden;
}
