.controller {
  position: relative;
  background-color: #f0f0f0;
  overflow: hidden;
}

.controller-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ccc;
  border-bottom: 1px solid #aaa;
}

.controller-options input[type="radio"] {
  display: none;
}

.controller-options div {
  display: flex;
  margin: 0;
  padding: 0;
}

.controller-options label span {
  display: block;
  padding: 8px 12px;
  background-color: #ccc;
  color: black;
  font-size: 1rem;
  cursor: pointer;
}

.controller-options label span:hover,
.controller-options input[type="radio"]:checked + span {
  background-color: #aaa;
}

.drag:after {
  content: "Drop a font file to load";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 2rem;
  text-transform: none;
}

.controller-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #000;
  font-size: 0.7rem;
  background-color: #ccc;
  border-top: 1px solid #aaa;
  padding: 5px 10px 8px;
}
