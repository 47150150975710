
.css {
  padding: 10px;
  width: calc(100% - 42px);
  height: calc(100% - 78px);
  padding: 0 10px 20px;
  overflow: scroll;
  font-size: 11px;
  font-family: Courier, monospace;
  color: #000;
}
