.dividerMouseArea {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.divider {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4;
  width: 20px;
  display: flex;
  background: linear-gradient(
    to right,
    transparent,
    transparent 8px,
    black 8px,
    white 9px,
    white 11px,
    black 11px,
    transparent 12px
  );
  align-items: center;
}

.handle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  border: 2px solid white;
  height: 40px;
  border-radius: 10px;
  background-color: #000;
  color: white;
  font-size: 2rem;
  cursor: grab;
  user-select: none;
}

.handle.dragging {
  cursor: grabbing;
}
